import { Types } from 'src/generated/types';
const RANGES = {
  UNDER: 'រង្វាន់លួងចិត្តតូច',
  OVER: 'រង្វាន់លួងចិត្តធំ',
  RANGE_1: 'រង្វាន់លួងចិត្តទី1',
  RANGE_2: 'រង្វាន់លួងចិត្តទី2',
  RANGE_3: 'រង្វាន់លួងចិត្តទី3',
  RANGE_4: 'រង្វាន់លួងចិត្តទី4',
  RANGE_5: 'រង្វាន់លួងចិត្តទី5',
  RANGE_6: 'រង្វាន់លួងចិត្តទី6(U)',
  RANGE_7: 'រង្វាន់លួងចិត្តទី7(O)',
  NUMBER: 'NUMBER',
  JACKPOT: 'JACKPOT',
};

const RANGE_1: Types.Range = {
  rate: '9.20',
  range: '55-238',
  value: '1',
  type: 'RANGE_1',
  title: 'គុណ',
};
const RANGE_2: Types.Range = {
  rate: '4.60',
  range: '239-274',
  value: '2',
  type: 'RANGE_2',
  title: 'គុណ',
};
const RANGE_3: Types.Range = {
  rate: '2.30',
  range: '275-325',
  value: '3',
  type: 'RANGE_3',
  title: 'គុណ',
};
const RANGE_4: Types.Range = {
  rate: '4.60',
  range: '326-361',
  value: '4',
  type: 'RANGE_4',
  title: 'គុណ',
};
const RANGE_5: Types.Range = {
  rate: '9.20',
  range: '362-545',
  value: '5',
  type: 'RANGE_5',
  title: 'គុណ',
};

const UNDER: Types.Range = {
  rate: '1.95',
  range: '55-300',
  value: 'U',
  type: 'RANGE_6',
  title: 'តូច (6)',
};

const OVER: Types.Range = {
  rate: '1.95',
  range: '301-545',
  value: 'O',
  type: 'RANGE_7',
  title: 'ធំ (7)',
};

const NUMBER: Types.Range = {
  rate: '',
  range: '',
  value: '',
  type: 'NUMBER',
  title: '',
};

const COLORS = [
  '#ffbf001a',
  '#de31631c',
  '#ff7f501c',
  '#6495ed1a',
  '#c58a7a45',
  '#69ff0017',
  '#da00ff14',
  '#de31631c',
  '#ff7f501c',
  '#6495ed1a',
  '#c58a7a45',
];
const TYPES = [
  { title: 'ទាំងអស់', value: 'ALL' },
  { title: 'Jackpotទាំងអស់', value: 'JACKPOT' },
  { title: 'ឈ្នះJackpot', value: 'WIN_JACKPOT' },
  { title: 'មិនមែនJackpot', value: 'NOT_JACKPOT' },
  { title: 'កំពុងចាក់', value: 'OUTSTANDING' },
  { title: RANGES.RANGE_1, value: 'RANGE_1' },
  { title: RANGES.RANGE_2, value: 'RANGE_2' },
  { title: RANGES.RANGE_3, value: 'RANGE_3' },
  { title: RANGES.RANGE_4, value: 'RANGE_4' },
  { title: RANGES.RANGE_5, value: 'RANGE_5' },
  { title: RANGES.RANGE_6, value: 'RANGE_6' },
  { title: RANGES.RANGE_7, value: 'RANGE_7' },
];

const POSTS = [
  { title: 'A', color: 'bg-red-500', border: 'border-red-500' },
  { title: 'B', color: 'bg-blue-500', border: 'border-blue-500' },
  { title: 'C', color: 'bg-green-500', border: 'border-green-500' },
  { title: 'D', color: 'bg-orange-500', border: 'border-orange-500' },
  { title: 'E', color: 'bg-yellow-500', border: 'border-yellow-500' },
];

const ERRORS = {
  min_bet: 'ចំនួនទឹកប្រាក់ចាក់យ៉ាងហោចណាស់',
  max_bet: 'ចំនួនទឹកប្រាក់ចាក់មិនអាចលើសពី',
  timeout: 'ពេលវេលាចាក់បានអស់ម៉ោង។',
  bet_failed: 'ការចាក់របស់អ្នកទទួលបានបរាជ័យ។',
  invalid: 'ចំនួនទឹកប្រាក់មិនត្រឹមត្រូវ។',
  invalid_posts: 'សូមជ្រើសរើសប៉ុស្តិ៍។',
  not_enough_money: 'ចំនួនទឹកប្រាក់មិនគ្រប់គ្រាន់។',
  bet_jackpot_over_limit: 'ការចាក់ Jackpot បានលើសចំនួនកំណត់ ',
};

const WARNING = {
  MIN: 'The amount must be at least ',
  MAX: 'The amount must not be greater than ',
  TIMEOUT: 'Request timeout.',
  NOT_ENOUGH_MONEY: 'Not enought money.',
  BET_JACKPOT_OVER_LIMIT: 'Bet jackpot is over limit ',
};
const MAINTENANCE =
  'សូមជម្រាបជូនដំណឹងដល់អតិថិជនទាំងអស់ឲ្យបានជ្រាបថា៖ ឆ្នោតឡូតូសេដ្ឋីនាគ(10ខ្ទង់) នឹងធ្វើបច្ចុប្បន្នកម្មប្រព័ន្ធស្នូលនៅពេលបន្តិចទៀតនេះ។ អាស្រ័យហេតុនេះ រាល់ប្រតិបត្តិការចាក់ទាំងអស់នឹងត្រូវបានផ្អាកជាបណ្តោះអាសន្ត។ សូមអរគុណ!';

const GAME_TYPES: { value: Types.Lottery.GameType; name: string }[] = [
  // { value: '5D', name: 'នាគ' },
  { value: 'VN', name: 'ឆ្នោតវៀតណាម' },
  { value: 'TN', name: 'ឆ្នោតធីងណាម' },
  { value: 'KH', name: 'ឆ្នោតខ្មែរ' },
];

const Setting = {
  RANGES,
  OVER,
  UNDER,
  TYPES,
  NUMBER,
  RANGE_1,
  RANGE_2,
  RANGE_3,
  RANGE_4,
  RANGE_5,
  RANGE_6: UNDER,
  RANGE_7: OVER,
  COLORS,
  POSTS,
  ERRORS,
  WARNING,
  MAINTENANCE,
  DELAY_RESULT: 1,
  RELEASE_RESULT_TIME: 5,
  TOTAL_RELEASE_RESULT_TIME: 65,
  NUMBER_RATE: 50,
  GAME_TYPES,
};

export default Setting;
