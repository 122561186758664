import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useAuthContext } from 'src/contexts/auth-provider';
import { Types } from 'src/generated/types';
import { HomeLayout } from 'src/layouts';
import MainLayout from 'src/layouts/mains';
import Paths from 'src/paths';

const HomePage = lazy(() => import('src/pages/homes/home-page'));

// Game
const GameLotto59Page = lazy(() => import('src/pages/games/game-lotto-59-page'));

// User
const UserChangePasswordPage = lazy(() => import('src/pages/users/user-change-password-page'));

// Report
const ReportLotto59Page = lazy(() => import('src/pages/reports/report-lotto-59-page'));

// Ticket
const TicketLotto59Page = lazy(() => import('src/pages/tickets/ticket-lotto-59-page'));

// Transaction
const DepositPage = lazy(() => import('src/pages/transactions/deposit-page'));
const WithdrawtPage = lazy(() => import('src/pages/transactions/withdraw-page'));

// Lottery
const GameLotteryPage = lazy(() => import('src/pages/games/game-lottery-page'));
const GameBetLotteryPage = lazy(() => import('src/pages/games/game-bet-lottery-page'));
const GameLotteryReportPage = lazy(() => import('src/pages/games/game-lottery-report-page'));
const GameLotteryTicketPage = lazy(() => import('src/pages/games/game-lottery-ticket-page'));
const GameLotteryResultPage = lazy(() => import('src/pages/games/game-lottery-result-page'));
const GameTimeoutLotteryPage = lazy(() => import('src/pages/games/game-timeout-lottery-page'));

// const { isDisable } = useAuthContext();
// console.log(isDisable);

export const mainRouters = () => {
  const { enableLottery } = useAuthContext();
  const route: Types.RouteObject[] = [
    {
      element: (
        <HomeLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </HomeLayout>
      ),
      children: [{ path: Paths.routes.home.root, element: <HomePage /> }],
    },
    {
      element: (
        <MainLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        { path: '/lotto', element: <Navigate to={Paths.routes.game.lotto59} replace /> },
        // Game
        { path: Paths.routes.game.lotto59, element: <GameLotto59Page /> },

        // User
        { path: Paths.routes.user.password, element: <UserChangePasswordPage /> },

        // Report
        { path: Paths.routes.report.lotto59, element: <ReportLotto59Page /> },

        // Ticket
        { path: `${Paths.routes.ticket.lotto59}/:id?`, element: <TicketLotto59Page /> },

        // Transaction
        { path: Paths.routes.transaction.deposit, element: <DepositPage /> },
        { path: Paths.routes.transaction.withdraw, element: <WithdrawtPage /> },
      ],
    },
    enableLottery
      ? {
          element: (
            <MainLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </MainLayout>
          ),
          children: [
            { path: Paths.routes.game.lottery.root, element: <GameLotteryPage /> },
            { path: `${Paths.routes.game.lottery.bet()}/:gameType?`, element: <GameBetLotteryPage /> },
            { path: Paths.routes.game.lottery.timeout, element: <GameTimeoutLotteryPage /> },
            { path: Paths.routes.game.lottery.result, element: <GameLotteryResultPage /> },
            { path: Paths.routes.game.lottery.report, element: <GameLotteryReportPage /> },
            { path: Paths.routes.game.lottery.ticket, element: <GameLotteryTicketPage /> },
          ],
        }
      : {},
  ];
  return route;
};
