import { ReactNode, useCallback, useState } from 'react';
import { MainHeader, MainMenuSlider } from './components';

export default function MainLayout({ children }: { children?: ReactNode }) {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const onInvisibleMenu = useCallback((value: boolean) => {
    setMenuVisible(value);
  }, []);

  return (
    <div className="bg-background max-h-screen min-h-screen overflow-hidden">
      <MainHeader onChangeMenuVisible={() => setMenuVisible(i => !i)} />
      <div className="lg:flex-row min-h-[calc(100vh-2.5rem)] md:min-h-[calc(100vh-2.75rem)]">
        <div
          onClick={() => onInvisibleMenu(false)}
          className={`fixed z-[100] ${
            menuVisible ? 'flex' : 'hidden'
          } lg:flex top-0 lg:relative h-full w-screen lg:w-auto max-h-screen lg:max-h-[calc(100vh-2.75rem)] bg-[#00000078] lg:bg-none`}>
          <MainMenuSlider isFullWidth={menuVisible} onInvisibleMenu={onInvisibleMenu} />
        </div>
        <div
          className={`min-w-full ${
            menuVisible ? 'lg:min-w-[calc(100vw-14rem)]' : 'lg:min-w-[calc(100vw-3.15rem)]'
          } max-h-max min-h-full overflow-y-auto p-1`}>
          {children}
        </div>
      </div>
    </div>
  );
}
