import { createRef } from 'react';
import { AlertAnnouncementProps, AlertAnnouncementRef } from 'src/components/alerts/alert-announcement';
import { AlertPopupProps, AlertPopupRef } from 'src/components/alerts/alert-popup';
import { AlertSnackbarRef } from 'src/components/alerts/alert-snackbar';
import { ConfirmPopupProps, ConfirmPopupRef } from 'src/components/alerts/confirm-popup';
import Setting from 'src/utils/setting';

export const ERROR_SOMETHING_WENT_WRONG = 'មានភាពមិនប្រក្រតីកើតឡើង។';
export const confirmPopupRef = createRef<ConfirmPopupRef>();
export const alertPopupRef = createRef<AlertPopupRef>();
export const alertSnackbarRef = createRef<AlertSnackbarRef>();
export const alertAnnouncementRef = createRef<AlertAnnouncementRef>();

export function alertConfirm(params: ConfirmPopupProps) {
  confirmPopupRef.current &&
    confirmPopupRef.current.show({
      ...params,
      status: 'ERROR',
    });
}

export function alertSuccess(params: AlertPopupProps) {
  alertPopupRef.current &&
    alertPopupRef.current.show({
      ...params,
      status: 'SUCCESS',
    });
}

export function alertError(params: AlertPopupProps) {
  alertPopupRef.current &&
    alertPopupRef.current.show({
      ...params,
      status: 'ERROR',
    });
}

export function alertUnknown(params?: AlertPopupProps) {
  alertPopupRef.current &&
    alertPopupRef.current.show({
      ...params,
      status: 'ERROR',
      message: ERROR_SOMETHING_WENT_WRONG,
    });
}

export function alertAnnouncement(params: AlertAnnouncementProps) {
  alertAnnouncementRef.current && alertAnnouncementRef.current.show(params);
}

export function alertMaintenance() {
  alertPopupRef.current &&
    alertPopupRef.current.show({
      status: 'ERROR',
      message: Setting.MAINTENANCE,
    });
}

export function snackbarSuccess(message: string = '') {
  alertSnackbarRef.current &&
    alertSnackbarRef.current.show({
      message: message || 'ការចាក់របស់អ្នកទទួលបានជោគជ័យ។',
      status: 'SUCCESS',
    });
}

export function snackbarError(message: string) {
  alertSnackbarRef.current &&
    alertSnackbarRef.current.show({
      message,
      status: 'ERROR',
    });
}

export function snackbarLoading() {
  alertSnackbarRef.current &&
    alertSnackbarRef.current.show({
      status: 'LOADING',
    });
}
