import { useMemo } from 'react';
import { useParams as _useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useParams<T = any>() : T {
  const params = _useParams() as T;

  return useMemo<T>(() => params, [params]);
}
