import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { ModalContainer } from '../modals';
import { forwardRef, useImperativeHandle, useState } from 'react';

export interface ConfirmPopupProps {
  message?: string;
  title?: string;
  status?: 'SUCCESS' | 'ERROR';
  onClick?: () => void;
}

export interface ConfirmPopupRef {
  show: (props: ConfirmPopupProps) => void;
}

const AlertPopup = forwardRef((_, ref?: any) => {
  const [msg, setMsg] = useState<ConfirmPopupProps | null>(null);

  const onClose = (e: MouseEvent | any) => {
    e.preventDefault();
    e.stopPropagation();
    setMsg(null);
  };

  const onAction = (e: MouseEvent | any) => {
    e.preventDefault();
    e.stopPropagation();
    msg && msg.onClick && msg.onClick();
    setMsg(null);
  };

  const show = (_msg: ConfirmPopupProps) => {
    setMsg(_msg);
  };

  useImperativeHandle(ref, () => ({ show }));

  return (
    <ModalContainer visible={true} hidden={!msg} className="z-[1000]">
      <div className="flex-1 items-center justify-center p-2">
        <LazyLoadComponent>
          <div className="bg-white p-2 rounded-lg w-full max-w-md relative border-5 border:bg-slate-100 shake">
            <div className="mb-2 items-center justify-center w-full min-h-[6rem] max-h-52 overflow-auto bg-gray-50 rounded">
              <label className="text-center text-sm md:text-base text-[#333] font-battambang">{msg?.message}</label>
            </div>
            <div className="flex flex-row gap-2">
              <button
                autoFocus
                onClick={onClose}
                onSubmit={onClose}
                className={`w-1/2 text-white rounded-md py-1.5 text-sm md:text-base font-battambang bg-gray-400`}>
                {'មិនយល់ព្រម'}
              </button>
              <button
                autoFocus
                onClick={onAction}
                onSubmit={onAction}
                className={`w-1/2 text-white rounded-md py-1.5 text-sm md:text-base font-battambang ${
                  msg?.status === 'ERROR' ? 'bg-error' : 'bg-success'
                }`}>
                {msg?.title || 'យល់ព្រម'}
              </button>
            </div>
          </div>
        </LazyLoadComponent>
      </div>
    </ModalContainer>
  );
});

export default AlertPopup;
